import { Link } from "@/components";
import { IViewTableColumn } from "@/components/table/ViewTable";
import { routerMap } from "@/router/agent_router";
import WebApi from "@/web/api";
import LibEnum from "lib/enum";
import React from "react";
import CommonReport from "../components/CommonReport";

export default class ManageFeeReportPage extends React.PureComponent {
  private summaryColumns = [
    {
      title: '加油(元)',
      field: 'totalOilAmount',
    },
    {
      title: '水费(元)',
      field: 'totalWaterAmount',
    },
    {
      title: '电费(元)',
      field: 'totalElectricityAmount',
    },
    {
      title: '工资(元)',
      field: 'totalSalaryAmount',
    },
    {
      title: '其他(元)',
      field: 'totalOtherAmount',
    },
  ];

  private columns: IViewTableColumn[] = [
    {
      title: '日期',
      dataIndex: 'date',
      width: 200,
      align: 'center',
    },
    {
      title: '加油(元)',
      dataIndex: 'oilAmount',
      align: 'center',
      sorter: (a: any, b: any) => a.oilAmount - b.oilAmount,
    },
    {
      title: '水费(元)',
      dataIndex: 'waterAmount',
      align: 'center',
      sorter: (a: any, b: any) => a.waterAmount - b.waterAmount,
    },
    {
      title: '电费(元)',
      dataIndex: 'electricityAmount',
      align: 'center',
      sorter: (a: any, b: any) => a.electricityAmount - b.electricityAmount,
    },
    {
      title: '工资(元)',
      dataIndex: 'salaryAmount',
      align: 'center',
      sorter: (a: any, b: any) => a.salaryAmount - b.salaryAmount,
    },
    {
      title: '其他(元)',
      dataIndex: 'otherAmount',
      align: 'center',
      sorter: (a: any, b: any) => a.otherAmount - b.otherAmount,
    },
  ];
  render() {
    return <CommonReport
      showEmptyLabelText="仅显示有发生额的日期"
      filter={data => {
        return data.filter(i => (i.electricityAmount > 0 || i.waterAmount > 0 || i.oilAmount > 0 || i.salaryAmount > 0 || i.otherAmount > 0));
      }}
      tableColumns={this.columns}
      pagination={false}
      summaryColumns={this.summaryColumns}
      fetch={data => WebApi.report_manage_fee(data)}
    />
  }
}